import {NuxtError, UseFetchOptions} from "#app"
import {FetchError} from "ofetch";
import {RuntimeConfig} from "@nuxt/schema";
import urlJoin from 'url-join';
import type {ApiErrorDataProps, ApiValidationErrorDataProps} from "~/stNuxtCommon/composables/api/ApiErrors";
import {useMyAdminSession} from "~/stNuxtCommon/composables/session/useMyAdminSession";

export type ApiRequestParams = Record<string, any>

export interface ApiFetchOptions {
  /**
   * クライアントでのみAPIコールするならtrue。
   * 例えばボタンを押した時などクライアントのみで行われる操作でAPIコールする場合はtrueにする。
   * デフォルトはfalseで、APIコールはuseFetchを用いる。
   * trueの場合、APIコールは$fetchを用いる。
   */
  isClientOnly: boolean
}
export const DefaultApiFetchOptions: ApiFetchOptions = {
  isClientOnly: false
}

type HttpMethod = "GET"|"POST"|"PATCH"|"PUT"|"DELETE"

function createDefaultOptions(config: RuntimeConfig, method: HttpMethod): UseFetchOptions<any> {
  let headers: Record<string, string>
  headers = createFrontendHeader(config)
  headers = Object.assign(headers, createAdminHeader(config))
  // これを指定するとクロスオリジン周りでエラーになるのでつけてはいけない
  // headers['Cache-Control'] = 'no-cache'

  return {
    headers: headers,
    method: method,
    // cacheを有効にするとuseFetchのdata.valueが空になることがある。
    // リクエストURLとパラメータの組み合わせによって起きるバグ？
    // いずれにせよ、キャッシュしたくないのでno-cacheを指定する。
    cache: "no-cache",
  }
}

function createFrontendHeader(config: RuntimeConfig): Record<string, string> {
  const headerKey = config.public.frontendApiAccessTokenRequestHeaderKey as string
  const token = config.public.frontendApiAccessToken as string

  let headers: Record<string, string> = {}
  headers[headerKey] = `Bearer ${token}`
  return headers
}

function createAdminHeader(config: RuntimeConfig): Record<string, string> {
  const adminSession = useMyAdminSession()
  if (!adminSession.isLoggedIn()) return {}

  const headerKey = config.public.adminApiAuthorizationRequestHeaderKey as string
  let headers: Record<string, string> = {}
  headers[headerKey] = `Bearer ${adminSession.apiAccessToken}`
  return headers
}

/**
 * FetchErrorをNuxtErrorに変換
 */
function nuxtErrorFromFetchError(value: FetchError<any>): NuxtError {
  if (!value.data) {
    const errorData: ApiErrorDataProps = {
      category: "api",
      type: "RequestError",
    }
    return createError({
      message: "Request error.",
      data: errorData
    })
  }

  const data = value.data
  if (data.validationError?.fieldErrors) {
    const errorData: ApiValidationErrorDataProps = {
      category: "api",
      type: "ValidationError",
      fieldErrors: value.data.validationError.fieldErrors,
    }
    return createError({
      statusCode: value.statusCode || 500,
      data: errorData,
    })
  } else {
    const errorData: ApiErrorDataProps = {
      category: "api",
      type: "ResponseError",
    }
    return createError({
      statusCode: value.statusCode || 500,
      message: data.message || "Unknown error.",
      data: errorData,
    })
  }
}

function requestLog(method: HttpMethod, path: string, options?: UseFetchOptions<any>) {
  let paramsJson = ""
  if (options?.query) {
    const json = JSON.stringify(options.query, null, 2)
    paramsJson = "\n" + json || "{}"
  }
  console.log(`${method} ${path}${paramsJson}`)
}

export type ApiClientAsyncData = {
  data: any
}

async function fetch(
  baseUrl: string,
  path: string,
  options: UseFetchOptions<any>,
  fetchOptions: ApiFetchOptions,
): Promise<ApiClientAsyncData> {
  const url = urlJoin(baseUrl, path)
  const method = (options?.method || "GET") as HttpMethod
  requestLog(method, url, options)

  const isUseFetch = !fetchOptions.isClientOnly
  if (isUseFetch) {
    // TODO: status codeのチェックをしていない！
    const {data, error, status} = await useFetch(url, options)
    if (error?.value) {
      throw nuxtErrorFromFetchError(error.value)
    }
    if (!data.value) {
      // awaitしているのにdataが空。
      // 以前はno-cacheを指定しなかったため、ここに来ることがあった。
      // ComponentでuseApiClientを呼び出した時もここに来る。
      throw createError(
        `API Request Error. useFetch returned status:${status.value}. url:${url}` +
        ' Don\'t call useFetch in the vue components because it will return an idle status.'
      )
    }
    return {data: data.value}
  } else {
    let headers: Headers | undefined
    if (options?.headers) {
      headers = options?.headers as Headers
    }
    // TODO: status codeのチェックをしていない！
    const data = await $fetch(url, {
      method: method,
      headers: headers,
      query: options?.query,
      body: options?.body,
    })
    return {data: data}
  }
}

export interface ApiClientBase {
  get(path: string, query: ApiRequestParams | null, fetchOptions: ApiFetchOptions): Promise<ApiClientAsyncData>
  delete(path: string, query: ApiRequestParams | null, fetchOptions: ApiFetchOptions): Promise<ApiClientAsyncData>
  post(path: string, query: ApiRequestParams | null, fetchOptions: ApiFetchOptions): Promise<ApiClientAsyncData>
  patch(path: string, query: ApiRequestParams | null, fetchOptions: ApiFetchOptions): Promise<ApiClientAsyncData>
  put(path: string, query: ApiRequestParams | null, fetchOptions: ApiFetchOptions): Promise<ApiClientAsyncData>
}

export const useApiClientBase = (baseUrl: string): ApiClientBase => {
  const config = useRuntimeConfig()

  return {
    get: async (path: string, query: ApiRequestParams | null = null, fetchOptions: ApiFetchOptions): Promise<ApiClientAsyncData> => {
      const options2 = createDefaultOptions(config, "GET")
      if (query) options2.query = query
      return await fetch(baseUrl, path, options2, fetchOptions)
    },
    delete: async (path: string, query: ApiRequestParams | null = null, fetchOptions: ApiFetchOptions): Promise<ApiClientAsyncData> => {
      const options2 = createDefaultOptions(config, "DELETE")
      if (query) options2.query = query
      return await fetch(baseUrl, path, options2, fetchOptions)
    },
    post: async (path: string, body: ApiRequestParams, fetchOptions: ApiFetchOptions): Promise<ApiClientAsyncData> => {
      const options2 = createDefaultOptions(config, "POST")
      options2.body = body
      return await fetch(baseUrl, path, options2, fetchOptions)
    },
    patch: async (path: string, body: ApiRequestParams, fetchOptions: ApiFetchOptions): Promise<ApiClientAsyncData>  => {
      const options2 = createDefaultOptions(config, "PATCH")
      options2.body = body
      return await fetch(baseUrl, path, options2, fetchOptions)
    },
    put: async (path: string, body: ApiRequestParams, fetchOptions: ApiFetchOptions): Promise<ApiClientAsyncData>  => {
      const options2 = createDefaultOptions(config, "PUT")
      options2.body = body
      return await fetch(baseUrl, path, options2, fetchOptions)
    },
  }
}
